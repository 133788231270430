import { userLogin, userLogout, userSessionChange } from 'user';
import { Identity } from '@m10s/account-sdk-browser';
const identity = new Identity({
    clientId: `${process.env.SPID_CLIENT_ID}`,
    env: `${process.env.SPID_ENV}`,
    redirectUri: window.location.href,
    sessionDomain: `${process.env.SPID_SESSION_DOMAIN}`,
});
function attachIdentityHooks(store) {
    identity.on('login', (data) => store.dispatch(userLogin(data)));
    identity.on('logout', () => store.dispatch(userLogout()));
    identity.on('sessionChange', (data) => store.dispatch(userSessionChange(data)));
    //////////////////////////
    /////////////////////////////////////////
    ////////////////////////////////////////////////////////////
    /////
    //////////////
    identity.isLoggedIn().then((isLoggedIn) => {
        if (isLoggedIn) {
            identity.getUser().then((data) => {
                store.dispatch(userLogin(data));
            });
        }
    });
}
export { attachIdentityHooks, identity };
export function goToSpidReceipt(externalPaymentOrderId) {
    const spidUrl = 'https://' + process.env.SPID_SERVER + '/';
    let target = spidUrl + 'account/purchasehistory?p2p_order_id=' + externalPaymentOrderId;
    target += '&redirect_uri=' + encodeURIComponent(location.href);
    return target;
}
