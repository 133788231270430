import { getAddresses } from 'actions/Address';
import { setCardHolderNameFromLogin } from 'actions/NewPaymentMethod';
import { getPaymentMethods } from 'actions/PaymentMethod';
import axios from 'axios';
import jwtDecode from 'jwt-decode';
import { identity } from 'utilities/spid';
export function userLogin(data) {
    return function (dispatch) {
        dispatch({ type: 'USER_LOGIN', payload: data });
        dispatch(setCardHolderNameFromLogin(data));
    };
}
export function userLogout() {
    return function (dispatch) {
        dispatch({ type: 'USER_LOGOUT' });
    };
}
export function userSessionChange(data) {
    return function (dispatch) {
        dispatch({ type: 'USER_SESSION_CHANGE', payload: data });
    };
}
export function validateJwt(jwt, tokenSource) {
    return function (dispatch) {
        dispatch({ type: 'VALIDATE_JWT', payload: { jwt } });
        axios
            .get(`${process.env.CORE_API}/spid/validateToken`, {
            headers: { Authorization: 'Bearer ' + jwt },
        })
            .then(() => {
            localStorage.setItem('jwt', jwt);
            const jwtData = jwtDecode(jwt);
            dispatch({
                type: 'VALIDATE_JWT_SUCCESS',
                payload: { jwt, jwtData, tokenSource },
            });
            dispatch(getAddresses());
            dispatch(getPaymentMethods());
        })
            .catch((err) => {
            console.error(err);
            dispatch({
                type: 'VALIDATE_JWT_FAILED',
                payload: { err, tokenSource },
            });
        });
    };
}
export function logout() {
    return function () {
        if (window.location.origin) {
            identity.redirectUri = window.location.origin;
        }
        identity.logout();
    };
}
export function webLogin(redirectTo) {
    const redirect = redirectTo || window.location.href;
    const config = {
        redirectUri: `${process.env.CORE_API}/spid/login`,
        preferPopup: false,
        state: JSON.stringify({
            redirectTo: redirect,
            shopId: process.env.SUBSCRIBE_SHOP_ID,
        }),
        scope: 'openid profile email phone address',
    };
    identity.login(config);
}
export function tryShowSimplifiedLoginWidget(userState) {
    const TWO_WEEKS_IN_MS = 1000 * 60 * 60 * 24 * 7 * 2;
    const simplifiedLoginDisplayedTimestamp = localStorage.getItem('simplifiedLoginDisplayedTimestamp');
    const simplifiedLoginRecentlyDisplayed = simplifiedLoginDisplayedTimestamp && new Date(+simplifiedLoginDisplayedTimestamp + TWO_WEEKS_IN_MS) > new Date();
    if (!(userState === null || userState === void 0 ? void 0 : userState.isLoggedIn) && !simplifiedLoginRecentlyDisplayed) {
        identity
            .showSimplifiedLoginWidget({
            redirectUri: `${process.env.CORE_API}/spid/login`,
            preferPopup: false,
            state: JSON.stringify({
                redirectTo: window.location.href,
                shopId: process.env.SUBSCRIBE_SHOP_ID,
            }),
            scope: 'openid profile email phone address',
        })
            .then(() => {
            localStorage.setItem('simplifiedLoginDisplayedTimestamp', Date.now().toString());
        });
    }
}
