import find from 'lodash/find'
import { fetchDeadline, getDistributionForRegion } from 'actions/Distribution'
import { getFreight } from 'cart'
import { handleNetworkError } from 'actions/Error'

import axios from 'axios'
import { push } from 'connected-react-router'
import { attemptFetchStock } from 'actions/Products'
import { setActiveAddress } from 'actions/Address'
import { isEmpty } from 'lodash'

export function getRegions() {
    return function (dispatch) {
        dispatch({ type: 'GET_REGIONS' })
        return axios
            .get(`${process.env.CORE_API}/region?shopId=${process.env.SUBSCRIBE_SHOP_ID}`)
            .then((response) => {
                dispatch({ type: 'GET_REGIONS_SUCCESS', payload: response.data })
            })
            .catch((err) => {
                console.error(err)
                dispatch({ type: 'GET_REGIONS_FAILED', payload: err })
                dispatch(handleNetworkError(err))
            })
    }
}

function setRegion(region, dispatch) {
    if (region) {
        dispatch(setActiveAddress(null))
        dispatch({ type: 'SET_REGION', payload: region })
        dispatch({ type: 'TOGGLE_REGION_WIDGET', payload: false })
        dispatch(attemptFetchStock())
        dispatch(fetchDeadline(region.id, null, null))
        dispatch(getDistributionForRegion(region.transportProduct))
        dispatch(getFreight())
    } else {
        dispatch({ type: 'SET_REGION_FAILED' })
    }
}

export function setRegionForExternalRegion(externalRegion, redirect = true) {
    return (dispatch, getState) => {
        const region = find(getState().region.regions, { externalRegion })

        setRegion(region, dispatch)
        redirect && region && dispatch(push('/'))
    }
}

export function setSubscriptionRegion() {
    return (dispatch, getState) => {
        const transportProduct = getState().subscription.subscriptionTransportProduct
        const region = find(getState().region.regions, { transportProduct })

        setRegion(region, dispatch)
    }
}

export function setRegionByRegionId(regionId) {
    return (dispatch, getState) => {
        const region = getState().region.regions.filter((r) => r.id === regionId)[0]
        const hasActiveAddress = !isEmpty(getState().address.active)
        if (region) {
            dispatch({ type: 'SET_REGION', payload: region })
            dispatch(attemptFetchStock())
            dispatch(fetchDeadline(region.id))
            dispatch(getFreight())
            if (!hasActiveAddress) {
                dispatch(getDistributionForRegion(region.transportProduct))
            }
        } else {
            dispatch({ type: 'SET_REGION_FAILED' })
        }
    }
}

export function getAutoRegion() {
    return (dispatch) => {
        dispatch({ type: 'GET_AUTO_REGION' })
        axios
            .get(`${process.env.CORE_API}/region/location`)
            .then(({ data }) => {
                dispatch({ type: 'GET_AUTO_REGION_SUCCESS', payload: data })
                dispatch(attemptFetchStock())
                dispatch(fetchDeadline(data.regionId))
                dispatch(getFreight())
                dispatch(getDistributionForRegion(data.transportProduct))
            })
            .catch((err) => {
                console.error(err)
                dispatch(handleNetworkError(err))
            })
    }
}
