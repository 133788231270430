import { NavLink } from 'react-router-dom';
import { SafeHtml } from 'common';
import React from 'react';
export default function ProductProducer({ producer }) {
    var _a;
    const hasProducerPage = producer === null || producer === void 0 ? void 0 : producer.slug;
    const hasDescription = producer === null || producer === void 0 ? void 0 : producer.description;
    if (!producer || !hasDescription) {
        return null;
    }
    return (React.createElement("div", { className: "product__content__producer" },
        React.createElement("div", { className: "product__content__producer__heading" },
            ((_a = producer.logo) === null || _a === void 0 ? void 0 : _a.small) && (React.createElement("img", { className: "product__content__producer__heading__logo", src: producer.logo.small, alt: "" })),
            React.createElement("h2", { className: "product__content__producer__heading__text" },
                "Om",
                ' ',
                " ",
                !hasProducerPage && producer.name,
                hasProducerPage &&
                    React.createElement(NavLink, { className: "product__content__header__vendor--producer", to: `/produsent/${producer.slug}` }, producer.name))),
        React.createElement(SafeHtml, { type: "p", html: producer.description })));
}
