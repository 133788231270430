import { fetchAllProductData, fetchMyProducts } from 'actions/Products';
import { fetchSubscription } from 'actions/Subscription';
import { BlogPost } from 'blog';
import classNames from 'classnames';
import { Footer } from 'common';
import CustomerService from 'components/CustomerService';
import Errors from 'components/Errors';
import Header from 'components/Header/Header';
import CannotDeliverNotification from 'components/Notifications/CannotDeliverNotification';
import DeliveryDateChangedNotification from 'components/Notifications/DeliveryDateChangedNotification';
import DistributionErrorNotification from 'components/Notifications/DistributionErrorNotification';
import PaymentMethod3DSecureCallbackHandler from 'components/Payment/PaymentMethod3DSecureCallbackHandler';
import SubHeader from 'components/SubHeader/SubHeader';
import SubscriptionNotification from 'components/Subscription/SubscriptionNotification';
import BottomBar from 'components/UI/BottomBar';
import LoadingScreen from 'components/UI/LoadingScreen';
import PageTransition from 'components/UI/PageTransition';
import { ConnectedRouter } from 'connected-react-router';
import CookieConsent from 'cookies/components/CookieConsent';
import { BannerStrips, fetchDynamicHomePage } from 'home';
import { NavigationHeader } from 'navigation';
import { DesktopNotification, MobileBottomNotification } from 'notification';
import React, { useEffect, useState } from 'react';
import Loadable from 'react-loadable';
import { useDispatch, useSelector } from 'react-redux';
import { Route, Switch } from 'react-router';
import { RegionSelectWidget } from '../../../home/components/RegionSelectWidget/RegionSelectWidget';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const Loading = (_props) => React.createElement(LoadingScreen, null);
const DynamicHomePage = Loadable({
    loader: () => import('home/pages/DynamicHomePage'),
    loading: Loading,
});
const Checkout = Loadable({
    loader: () => import('components/Checkout'),
    loading: Loading,
});
const AllProductsPage = Loadable({
    loader: () => import('product/pages/ProductListPage/AllProductsPage'),
    loading: Loading,
});
const OrdersPage = Loadable({
    loader: () => import('components/Order/OrdersPage'),
    loading: Loading,
});
const OrderDetailsPage = Loadable({
    loader: () => import('components/Order/OrderDetailsPage'),
    loading: Loading,
});
const CartPage = Loadable({
    loader: () => import('cart/pages/CartPage/CartPage'),
    loading: Loading,
});
const ProductModal = Loadable({
    loader: () => import('components/Product/ProductModal/ProductModal'),
    loading: Loading,
});
const MobileMyPage = Loadable({
    loader: () => import('components/MobileMyPage'),
    loading: Loading,
});
const NetworkErrorPage = Loadable({
    loader: () => import('components/NetworkErrorPage'),
    loading: Loading,
});
const NotificationsPage = Loadable({
    loader: () => import('components/Notifications/NotificationsPage'),
    loading: Loading,
});
const ProductPage = Loadable({
    loader: () => import('product/pages/ProductPage/ProductPage'),
    loading: Loading,
});
const ProducerPage = Loadable({
    loader: () => import('producer/pages/ProducerPage'),
    loading: Loading,
});
const SearchPage = Loadable({
    loader: () => import('components/Search/SearchPage/SearchPage'),
    loading: Loading,
});
const SubscriptionPage = Loadable({
    loader: () => import('components/SubscriptionPage'),
    loading: Loading,
});
const PaymentMethodsPage = Loadable({
    loader: () => import('components/PaymentMethodsPage'),
    loading: Loading,
});
const SubscriptionPausePage = Loadable({
    loader: () => import('components/SubscriptionPausePage'),
    loading: Loading,
});
const AddressPage = Loadable({
    loader: () => import('address/pages/AddressPage'),
    loading: Loading,
});
const DeliveryPage = Loadable({
    loader: () => import('delivery/components/DeliveryPage'),
    loading: Loading,
});
const NewCheckoutPage = Loadable({
    loader: () => import('checkout/pages/NewCheckoutPage'),
    loading: Loading,
});
const DynamicConceptPage = Loadable({
    loader: () => import('conceptPage/pages/DynamicConceptPage'),
    loading: Loading,
});
const ProducerListPage = Loadable({
    loader: () => import('producer/pages/ProducerListPage'),
    loading: Loading,
});
const DynamicBlogPostsPage = Loadable({
    loader: () => import('blog/pages/BlogPostsPage/DynamicBlogPostsPage'),
    loading: Loading,
});
const DynamicInformationPage = Loadable({
    loader: () => import('informationPage/DynamicInformationPage'),
    loading: Loading,
});
const isModal = (location) => {
    return !!(location.state && location.state.modal);
};
const fetchData = (region, dispatch) => {
    dispatch(fetchAllProductData(region.slug, region.id));
    dispatch(fetchDynamicHomePage(region.id));
    dispatch(fetchSubscription());
    // Refetch the data in one hour
    return setTimeout(fetchData.bind(this, region, dispatch), 60 * 60 * 1000);
};
function Router({ history }) {
    const dispatch = useDispatch();
    const region = useSelector((state) => state.region);
    const isLoggedIn = useSelector((state) => state.user.isLoggedIn);
    const { isApp, isMobile, isTablet, showNetworkErrorPage: isNetworkError, } = useSelector((state) => state.ui);
    const hasVisibleNotification = useSelector((state) => state.notification.notifications.length > 0);
    const router = useSelector((state) => state.router);
    const location = router.location || history.location;
    const [refetchTimout, setRefetchTimeout] = useState();
    const [previousLocation, setPreviousLocation] = useState(location);
    useEffect(() => {
        if (history.action !== 'POP' && !isModal(location)) {
            setPreviousLocation(location);
        }
    }, [history.action, location]);
    useEffect(() => {
        if (region.slug) {
            if (refetchTimout) {
                clearTimeout(refetchTimout);
            }
            const timeout = fetchData(region, dispatch);
            setRefetchTimeout(timeout);
        }
        return () => {
            if (refetchTimout) {
                clearTimeout(refetchTimout);
            }
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, region.slug]);
    useEffect(() => {
        dispatch(fetchMyProducts());
        dispatch(fetchSubscription());
    }, [dispatch, isLoggedIn]);
    // If a modal is displayed, the underlying page should be used for UI logic
    const pageLocation = !isMobile && isModal(location) ? previousLocation : location;
    const isCheckout = new RegExp(/^\/checkout/).test(pageLocation.pathname);
    const isCart = RegExp(/^\/cart/).test(pageLocation.pathname);
    const isDelivery = RegExp(/^\/delivery$/).test(pageLocation.pathname);
    const isProduct = RegExp(/^\/product/).test(pageLocation.pathname);
    const isCategory = RegExp(/^\/categor/).test(pageLocation.pathname);
    const isProducerPage = /^\/produsent/.test(pageLocation.pathname);
    // Hide the delivery date and address dropdown on selected pages
    const isPageWithoutSubHeader = isNetworkError ||
        isCheckout ||
        isCart ||
        pageLocation.pathname === '/subscription' ||
        pageLocation.pathname === '/my-page/orders' ||
        pageLocation.pathname === '/my-page/addresses';
    // Hide the header in the sub checkout pages
    const hideHeader = /.*checkout\/\w+/.test(pageLocation.pathname) &&
        !pageLocation.pathname.startsWith('/checkout/complete');
    // Hide the footer in the app and other selected pages
    const hideFooter = isApp ||
        isCart ||
        isCheckout ||
        ((isMobile || isTablet) && (isDelivery || isCategory || isProduct || isProducerPage));
    // Show bottom navigation on mobile and tablet with some exceptions
    const showMobileBottomNavigation = (isMobile || isTablet) && !isCart && !isDelivery && !isCheckout;
    return (React.createElement(React.Fragment, null,
        React.createElement(ConnectedRouter, { history: history },
            React.createElement("div", null,
                React.createElement(PageTransition, { history: history, location: pageLocation, isMobile: isMobile },
                    React.createElement(BannerStrips, { page: pageLocation.pathname }),
                    React.createElement(RegionSelectWidget, null),
                    !isMobile && !isTablet && (React.createElement("div", { style: { position: 'sticky', top: 0, zIndex: 999 } },
                        React.createElement(Header, null),
                        !isPageWithoutSubHeader && React.createElement(SubHeader, null),
                        isPageWithoutSubHeader && React.createElement(DesktopNotification, null))),
                    (isMobile || isTablet) && !hideHeader && (React.createElement(NavigationHeader, null)),
                    React.createElement(CookieConsent, null),
                    React.createElement(Route, { path: "/checkout", component: Errors }),
                    React.createElement("div", { className: "main" },
                        isNetworkError ? (React.createElement(NetworkErrorPage, null)) : (React.createElement(Switch, { location: pageLocation },
                            React.createElement(Route, { exact: true, path: "/checkout", component: NewCheckoutPage }),
                            React.createElement(Route, { path: "/checkout", component: Checkout }),
                            React.createElement(Route, { path: "/delivery", component: DeliveryPage }),
                            React.createElement(Route, { path: "/products/:category?/:subcategory?", component: AllProductsPage }),
                            React.createElement(Route, { path: "/cart", component: CartPage }),
                            React.createElement(Route, { path: "/my-page", exact: true, component: MobileMyPage }),
                            React.createElement(Route, { path: "/my-page/orders", component: OrdersPage }),
                            React.createElement(Route, { path: "/my-page/payment-methods", component: PaymentMethodsPage }),
                            React.createElement(Route, { path: "/my-page/order/:orderId", component: OrderDetailsPage }),
                            React.createElement(Route, { path: "/my-page/notifications", component: NotificationsPage }),
                            React.createElement(Route, { path: "/my-page/addresses", component: AddressPage }),
                            React.createElement(Route, { path: "/subscription", component: SubscriptionPage }),
                            React.createElement(Route, { path: "/subscriptionPause", component: SubscriptionPausePage }),
                            React.createElement(Route, { path: "/product/:productId", component: ProductPage }),
                            React.createElement(Route, { path: "/search/:query", component: SearchPage }),
                            React.createElement(Route, { path: "/3dsecure/paymentmethod", component: PaymentMethod3DSecureCallbackHandler }),
                            React.createElement(Route, { path: "/produsenter", component: ProducerListPage }),
                            React.createElement(Route, { path: "/produsent/:producerSlug", component: ProducerPage }),
                            React.createElement(Route, { path: "/magasinet/:slug", component: BlogPost }),
                            React.createElement(Route, { path: "/magasinet", component: DynamicBlogPostsPage }),
                            React.createElement(Route, { path: "/informasjon/:slug", component: DynamicInformationPage }),
                            React.createElement(Route, { path: "/:conceptPageSlug", component: DynamicConceptPage }),
                            React.createElement(Route, { path: "/", component: DynamicHomePage }))),
                        React.createElement(SubscriptionNotification, null)),
                    !hideFooter && React.createElement(Footer, null),
                    React.createElement("div", { className: classNames({
                            'bottom-space': showMobileBottomNavigation && hasVisibleNotification,
                        }) })),
                React.createElement(Route, { path: "/product/:productId", component: ProductModal }),
                (isMobile || isTablet) && (React.createElement(BottomBar, null,
                    React.createElement(MobileBottomNotification, null))),
                ((!isMobile && !isTablet) || showMobileBottomNavigation) && (React.createElement(CustomerService, null)),
                React.createElement(CannotDeliverNotification, null),
                React.createElement(DistributionErrorNotification, null),
                React.createElement(DeliveryDateChangedNotification, null)))));
}
export default Router;
