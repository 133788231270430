import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import ReactSelect, { components } from 'react-select';
import './style.scss';
const Select = ({ option, options, onChange }) => {
    const [selectedOption, setSelectedOption] = useState(option);
    const [menuIsOpen, setMenuIsOpen] = useState(false);
    useEffect(() => {
        if (option.value !== selectedOption.value) {
            setSelectedOption(option);
        }
    }, [option, selectedOption, setSelectedOption]);
    const handleChange = (selected) => {
        if (selected) {
            setSelectedOption(selected);
            if (onChange) {
                onChange(selected.value);
            }
        }
    };
    const handleMenuOpen = () => {
        setMenuIsOpen(true);
    };
    const handleMenuClose = () => {
        setMenuIsOpen(false);
    };
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const CustomOption = (props) => {
        return props.isSelected ? (React.createElement("div", { className: "select__custom-option select__custom-option--is-selected" },
            React.createElement(components.Option, Object.assign({}, props)))) : (React.createElement("div", { className: "select__custom-option" },
            React.createElement(components.Option, Object.assign({}, props))));
    };
    const customStyles = {
        control: (styles) => (Object.assign(Object.assign({}, styles), { borderBottomLeftRadius: menuIsOpen ? 0 : styles.borderBottomLeftRadius, borderBottomRightRadius: menuIsOpen ? 0 : styles.borderBottomRightRadius })),
    };
    return (React.createElement(ReactSelect, { styles: customStyles, className: "select", classNamePrefix: "select", name: "region", components: { Option: CustomOption }, placeholder: "Region", value: selectedOption, onChange: handleChange, onMenuOpen: handleMenuOpen, onMenuClose: handleMenuClose, options: options, isSearchable: false }));
};
export default connect()(Select);
