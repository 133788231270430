export const baseState = {
    bannerStrips: {},
    hideProducerListImages: false,
    isApp: false,
    isMobile: false,
    isTablet: false,
    modalOpen: false,
    showAddressDropdown: false,
    showApartmentNumberHelpModal: false,
    showCart: false,
    showCheckoutValidationNotification: false,
    showDeliveryDateChangeNotification: false,
    showDeliveryDatesDropdown: false,
    showNetworkErrorPage: false,
    showUserDropdown: false,
    showRegionWidget: false,
};
const resetState = (state) => (Object.assign(Object.assign({}, baseState), { bannerStrips: state.bannerStrips, isApp: state.isApp, isMobile: state.isMobile, isTablet: state.isTablet, showDeliveryDateChangeNotification: state.showDeliveryDateChangeNotification, showRegionWidget: state.showRegionWidget }));
export default function reducer(state = Object.assign({}, baseState), action) {
    switch (action.type) {
        case 'SET_BANNER_STRIPS':
            return Object.assign(Object.assign({}, state), { bannerStrips: Object.assign(Object.assign({}, state.bannerStrips), { [action.payload.page]: {
                        bannerStrip1: action.payload.bannerStrip1,
                        bannerStrip2: action.payload.bannerStrip2,
                    } }) });
        case 'TOGGLE_CART':
            return Object.assign(Object.assign({}, resetState(state)), { showCart: action.payload != undefined ? action.payload : !state.showCart });
        case 'TOGGLE_DELIVERY_DATE_DROPDOWN':
            return Object.assign(Object.assign({}, resetState(state)), { showDeliveryDatesDropdown: action.payload != undefined ? action.payload : !state.showDeliveryDatesDropdown });
        case 'TOGGLE_USER_DROPDOWN':
            return Object.assign(Object.assign({}, resetState(state)), { showUserDropdown: action.payload != undefined ? action.payload : !state.showUserDropdown });
        case 'TOGGLE_REGION_WIDGET':
            return Object.assign(Object.assign({}, resetState(state)), { showRegionWidget: action.payload != undefined ? action.payload : !state.showRegionWidget });
        case 'TOGGLE_ADDRESS_DROPDOWN':
            return Object.assign(Object.assign({}, resetState(state)), { showAddressDropdown: action.payload != undefined ? action.payload : !state.showAddressDropdown });
        case 'HIDE_DROPDOWNS':
            return resetState(state);
        case 'CLOSE_CHECKOUT_VALIDATION_NOTIFICATION':
            return Object.assign(Object.assign({}, state), { showCheckoutValidationNotification: false });
        case 'ORDER_VALIDATION_FAILED':
            return Object.assign(Object.assign({}, state), { showCheckoutValidationNotification: true });
        case 'TOGGLE_APARTMENT_NUMBER_HELP_MODAL':
            return Object.assign(Object.assign({}, state), { showApartmentNumberHelpModal: action.payload != undefined
                    ? action.payload
                    : !state.showApartmentNumberHelpModal });
        case 'TOGGLE_IS_TABLET':
            return Object.assign(Object.assign({}, state), { isTablet: action.payload });
        case 'TOGGLE_IS_MOBILE':
            return Object.assign(Object.assign({}, state), { isMobile: action.payload });
        case 'IS_APP':
            return Object.assign(Object.assign({}, state), { isApp: action.payload });
        case 'SHOW_DELIVERY_DATE_CHANGED_NOTIFICATION':
            return Object.assign(Object.assign({}, state), { showDeliveryDateChangeNotification: true });
        case 'CLOSE_DELIVERY_DATE_CHANGED_NOTIFICATION':
            return Object.assign(Object.assign({}, state), { showDeliveryDateChangeNotification: false });
        case 'TOGGLE_SHOW_NETWORK_ERROR_PAGE':
            return Object.assign(Object.assign({}, state), { showNetworkErrorPage: action.payload });
        case 'TOGGLE_PRODUCER_LIST_IMAGES':
            return Object.assign(Object.assign({}, state), { hideProducerListImages: action.payload });
        case 'TOGGLE_MODAL_OPEN':
            return Object.assign(Object.assign({}, state), { modalOpen: action.payload });
        default:
            return state;
    }
}
