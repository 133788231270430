import React from 'react';
import RegionDropDown from 'components/RegionDropDown/RegionDropDown';
import './RegionSelectWidget.scss';
import Icon from 'components/UI/Icon';
import { useSelector } from 'react-redux';
const marker = require('img/marker.svg');
const Header = () => (React.createElement("div", { className: "region-select-widget__header" },
    React.createElement(Icon, { className: "region-select-widget__header__icon", svg: marker, size: "medium" }),
    React.createElement("h3", { className: "" }, "Velg omr\u00E5de for \u00E5 se riktig utvalg")));
const WidgetSelect = ({ options, onChange }) => {
    return React.createElement("div", { className: "widget-select" }, options.map((option) => {
        return (React.createElement("button", { value: option.value, key: option.value, onClick: () => onChange && onChange(option.value), className: "widget-select__item" }, option.label));
    }));
};
export const RegionSelectWidget = () => {
    const show = useSelector((state) => state.ui.showRegionWidget);
    const activeAddress = useSelector((state) => { var _a; return (_a = state.address.active) === null || _a === void 0 ? void 0 : _a.deliveryAddress; });
    if (!show || activeAddress) {
        return null;
    }
    return (React.createElement("div", { className: "region-select-widget-overlay" },
        React.createElement("div", { className: 'region-select-widget' },
            React.createElement(RegionDropDown, { redirect: false, header: React.createElement(Header, null), selectType: WidgetSelect }))));
};
